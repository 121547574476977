/* GlobalStyles.css */
:root {
  --primary-color: #005f73; /* Primary color for your app */
  --secondary-color: #0a9396; /* Secondary color */
  --accent-color: #94d2bd; /* Accent color */
  --text-color: #001219; /* Primary text color */
  --background-color: #e9d8a6; /* Background color */
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(to bottom, #60bbcf, #859ca1);
  color: #333;
}

/* App.css */
.App {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.workspace {
  display: flex;
  flex-direction: column;
  height: 100vh;
}