/* StatusBar.css */
.status-bar {
    background-color: #21456d; /* Dark blue, similar to the active tab in TabBar */
    color: white;
    padding: 5px 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    border-top: 1px solid #3b597a; /* Slightly lighter blue for the top border */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
