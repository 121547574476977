/* Icon Bar Styles */
.menu-icon-bar {
    background-color: #4e7296; /* Same as TabBar unselected tab */
    color: white;
  }
  
  /* Icon Style */
  .menu-icon-bar .bi {
    color: white;
    font-size: 1.5em; /* Adjust size for better visibility */
  }
  
  /* Tooltip Style */
  .tooltip-inner {
    background-color: #21456d; /* Dark color for tooltip background */
    color: white;
  }
  
  /* Vertical Separator in the icon bar */
  .vertical-separator {
    border-left: 1px solid #333; /* Adjust color to fit the theme */
    height: 30px;
    margin: 0 12px;
  }
  