.navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 40px !important;
}

.navbar a {
    padding-top: 0 !important;
    padding-bottom: 10 !important;
}

/* Responsive adjustments */
@media (max-width: 992px) { /* Adjust based on your preference */
    .navbar {
        padding: 10px;
    }

    .navbar-nav .nav-link {
        padding: 5px 10px;
    }
}
