/* Footer.css */
.footer {
    background-color: #f1f1f1; /* Light grey background */
    text-align: center;
    padding: 10px 20px; /* Adjust padding as needed */
    color: #333; /* Darker text color for readability */
    font-size: 12px;
    overflow: hidden; /* Hide overflow */
    box-sizing: border-box; /* Border-box sizing */
  }
  
  .footer-text {
    margin: 0 auto; /* Center align */
    max-width: 100%; /* Ensure it doesn't exceed its container */
    word-wrap: break-word; /* Break words to prevent overflow */
  }
  