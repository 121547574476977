/* Editor.css */
.Editor {
    border: 2px solid #21456d;   
  }
  
  /*
   flex-grow: 1;
    padding: 20px;
    background-color: #fff;
    border: 2px solid #3b597a;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    line-height: 1.6;
    min-height: 300px;
    color: #333;
  */