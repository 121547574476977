/* TabBar.css */
.custom-tab-bar {
    background-color: #99a6b4;
    border-bottom: 2px solid #21456d;
    padding-top: 3px;
  }
  
  .tab-item {
    margin-right: 3px;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }
  
  /* Lighter background for unselected tabs */
  .tab-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #fff;
    border: none;
    border-radius: 4px 4px 0 0;
    background-color: #6b82a2;
    transition: background-color 0.3s;
  }
  
  /* Intermediate shade on hover */
  .tab-link:hover {
    background-color: #3b597a;
    color: white;
    text-decoration: none;
  }
  
  /* Darker background for selected tab */
  .tab-link-active {
    background-color: #21456d;
  }

  .nav-tabs .nav-item .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #21456d; /* Your desired color */
    color: #fff;
}
  
  .rename-input {
    border-radius: 0;
    margin: 0;
    height: 100%;
  }
  
  .close-icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-left: 10px;
    background-color: #e74c3c;
    border-radius: 2px;
  }
  
  .close-icon {
    color: #fff;
    cursor: pointer;
  }
  
  .close-icon:hover {
    color: #bdc3c7;
  }